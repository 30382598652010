import React, { useState, useEffect, useContext } from 'react'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import queryString from 'query-string'
import { get, isNil } from 'lodash'
import { AudioPlayerProvider } from 'react-use-audio-player'
import * as ls from 'local-storage'
import { Layout, Login, Slideshow } from '@catamaran/components'
import { UserContext, UserContextType, USER_REDUCERS, AudioPlayer } from '@catamaran/utils'

const LOGIN = gql`
  mutation login($token: String, $accessToken: String) {
    login(input: { token: $token, accessToken: $accessToken }) {
      token
      user {
        id
        first_name
        last_name
      }
      error
    }
  }
`

export default function Index() {
  const [triedLogin, setTriedLogin] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [accessTokenSet, setAccessTokenSet] = useState(false)
  const [code, setCode] = useState(null)
  const [loginMutation] = useMutation(LOGIN)
  // @ts-ignore
  const [state, dispatch] = useContext(UserContext)
  const context: UserContextType = state

  const getToken = () => {
    try {
      const query = queryString.parse(get(location, 'search'))
      return get(query, 'token') ?? ls.get('accessToken')
    } catch (_) {
      return null
    }
  }

  const login = async () => {
    setLoading(true)
    setTriedLogin(true)

    let query = null

    try {
      query = queryString.parse(get(location, 'search'))
    } catch (_) {}

    try {
      if (get(query, 'token')) {
        const result = await loginMutation({ variables: { token: get(query, 'token') } })
        const data = result.data.login

        if (data.error) {
          setError(data.error)
        } else if (data.token) {
          ls.set('accessToken', data.token)
          setAccessTokenSet(true)

          dispatch({
            type: USER_REDUCERS.updateUser,
            payload: {
              ...data.user,
            },
          })
        }
      } else if (ls.get('accessToken')) {
        const result = await loginMutation({ variables: { accessToken: ls.get('accessToken') } })
        const data = result.data.login

        if (data.error || result.errors) {
          setError(data.error || result.errors[0].message)
          ls.set('accessToken', null)
        } else if (data.token) {
          ls.set('accessToken', data.token)
          setAccessTokenSet(true)

          dispatch({
            type: USER_REDUCERS.updateUser,
            payload: {
              ...data.user,
            },
          })
        }
      } else if (!error) {
        setError('Invalid login link!')
        dispatch({
          type: USER_REDUCERS.logout,
        })
      }
    } catch (e) {
      if (get(e, 'graphQLErrors.0.message') === 'Not Authorised!') {
        setError('Session expired!')
      } else {
        setError(e)
      }

      dispatch({
        type: USER_REDUCERS.logout,
      })
    }

    setLoading(false)
  }

  const onSubmit = (e) => {
    e.preventDefault()
    window.location.href = `/?token=${code}`
  }

  if (!triedLogin && getToken()) {
    login()
  } else if (!triedLogin && !getToken()) {
    return (
      <Layout>
        <div className="m-auto">
          <div className="max-w-sm rounded overflow-hidden shadow-lg bg-white">
            <div className="px-6 py-4">
              <div className="flex flex-col items-center mb-6 mt-4">
                <img src="/logo.svg" className="w-40 mb-4" />
                <div className="font-medium text-l mb-8 text-gray-800">Orientation</div>
                <div className="flex flex-col">Enter Code</div>
                <form className="flex flex-col space-y-3 pt-5" onSubmit={onSubmit}>
                  <input
                    className="border rounded-md py-1 px-2"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                  />
                  <button
                    type="submit"
                    className="rounded-md bg-lightTeal hover:bg-darkTeal hover:text-white w-full py-1">
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }

  const audioPlayer = new AudioPlayer()

  return (
    <Layout>
      {loading && <Login loading={loading} />}
      {error && !loading && <Login error={error} />}
      {!loading && !error && !isNil(ls.get('accessToken')) && (
        <AudioPlayerProvider>
          <Slideshow audioPlayer={audioPlayer} />
        </AudioPlayerProvider>
      )}
      {!loading && !error && isNil(ls.get('accessToken')) && <Login error="Logged Out!" />}
    </Layout>
  )
}
